@use 'variables' as *;

button::-webkit-outer-spin-button,
button::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.btn {
  &-primary,
  &-secondary {
    border-radius: 0;
    min-width: 200px;
    height: 60px;
    font-size: 1.125rem;
    font-weight: 700;
    background-color: $body-bg;
    border: 0 none;
    border: 1px solid $color-white;

    &:not(.btn-primary):not(:disabled):not(.disabled):hover,
    &:not(.btn-primary):not(:disabled):not(.disabled):active,
    &:not(.btn-primary):not(:disabled):not(.disabled):focus {
      background-color: $body-bg;
      border: 1px solid $color-white;
    }

    a,
    a:active,
    a:visited,
    a:hover {
      color: inherit;
      text-decoration: none;
    }
  }

  &--rounded {
    border-radius: 60px;
  }

  &-secondary {
    &.disabled,
    &:disabled {
      opacity: 0.6;
    }
  }

  &-secondary-negative,
  &-secondary-negative:hover {
    background-color: color('white');
    color: color('secondary');
  }

  &--short {
    height: 44px;
    padding: 0 24px 0 16px;
    min-width: unset;
  }

  &-primary {
    background: $gradient-primary;
    border: 0 none;
  }

  & > i {
    font-size: 20px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 7px;
    line-height: 0;
  }

  &-block {
    display: block;
    width: 100%;
  }

  &[disabled],
  &.isDisabled {
    opacity: 0.4;
    pointer-events: none;
  }
}

a {
  &.btn {
    &-primary,
    &-secondary {
      padding-top: 0;
      padding-bottom: 0;
      line-height: 60px;
    }
  }
}

.link {
  cursor: pointer;

  text-decoration: underline;
  color: color(primary);
}
