.table {
  margin-bottom: 2rem;
  color: white;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 10px;
  th,
  tr,
  thead th,
  tbody,
  td {
    border: 0 none;
    border-collapse: collapse;
    border-spacing:0;
    font-size: 16px;
  }


  th,
  td {
    text-align: center;
    margin: auto;
    vertical-align: middle;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      max-width: 64px;
      min-width: 64px;
      margin-right: 0px;
      text-align: right;

      app-radio-button {
        display: inline-block;
        vertical-align: middle;
      }
    }
  }

  thead {
    th {
      padding: 0;
      padding-bottom: 10px;
      font-size: 0.75rem;
      font-weight: 400;
      text-align: center;
      text-transform: uppercase;
      letter-spacing: 0.17em;
    }
  }

  tbody {
    tr {
      height: 64px;


      &:last-child {
        margin-bottom: 0;
      }

      &:hover {
        td {
          background-color: lighten($color-light-blue, 10%);
        }
      }

      &.checked {
        td {
          background-color: $color-dark-brown !important;
        }


      }

      td {
        cursor: pointer;
        background-color: $color-light-blue;
        font-weight: 600;
        transition: $time-transition ease-in-out;

        &:first-child {
          border-bottom-left-radius: 6px;
          border-top-left-radius: 6px;
        }

        &:last-child {
          border-bottom-right-radius: 6px;
          border-top-right-radius: 6px;
        }
      }
    }
  }
}
