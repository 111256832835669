@use '../global/base/_base';
@import 'variables';

@import '../global/bootstraps';
@import '../global/dmarin-icons-font/dmarin-font-icons';

@import 'typography';
@import 'template';

@import 'components/buttons';
@import '../global/components/icons';
@import '../global/components/wrap';
@import 'components/hr';
@import 'components/datepicker';
@import 'components/table';
@import 'components/tooltip';
@import 'components/modal';
@import '../global/components/google-map';

body {
  background: $body-bg;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
label {
  display: inline-block;
}
html {
  -webkit-text-size-adjust: 100%;
}
