hr {
  margin-top: 3.125rem;
  margin-bottom: 3.125rem;
  border: 0;
  opacity: 1;
  border-top: 1px solid $color-light-blue;
  &.light{
    opacity: .1;
    border-top: 1px solid white;
  }
}
