@forward './../../global/variables';

@use './../../global/variables' as *;

$color-white: #ffffff;
$color-dark-blue: #051d40;
$color-light-blue: #143159;
$color-oxford-blue: #102747;
$color-dark-brown: #c26648;
$color-kashmir: #c26648;
$color-light-brown: #eb977f;
$success: #2ac565;
$color-orange: #ffb13c;
$color-primary: $color-dark-brown;
$gradient-primary: linear-gradient(270deg, #c26648 0%, #eb977f 100%);
$color-gray: #ccc;
$color-gray-light: #dae2eb;
$body-bg: $color-dark-blue;
$body-color: $color-white;
$color-black: $color-dark-blue;
$color-gray-lighter: $color-gray;
$color-red: #f33;

@include define-colors(
  (
    'primary': $color-dark-brown,
    'secondary': $color-dark-blue,
    'formBorder': $color-light-blue,
    'text': white,
    'white': white,
    'gradient primary': $gradient-primary,
    'formLabel': $color-white,
    'navTabsColor': $color-gray,
    'bgDark': $color-black,
    'grey': $color-gray,
    'grey light': $color-gray-light,
    'error': #eb977f,
    'red': $color-red,
    'black': $color-black,
    'input background disabled': $color-oxford-blue,
    'success': $success,
    'tooltip background': $color-gray-lighter,
    'tooltip text': $color-oxford-blue,
    'bg': $body-bg,
    'warning': $color-orange,
  )
);

$base-font-family: 'Montserrat', sans-serif;
$maxWidth: 1440px;
