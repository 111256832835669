@use 'variables' as *;

.modal {
  &-header {
    position: relative;
    padding: 30px 40px 15px;
    border: 0;

    .title {
      font-weight: 600;
      font-size: 24px;
      line-height: 29px;
      text-transform: uppercase;
    }

    .close-button {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 16px;
      color: color('white');
      font-size: 0;
      .icon {
        font-size: 28px;
      }
    }
  }
  &-content {
    display: block;
    background: $body-bg;
  }

  &-body {
    padding: 16px 40px;
  }
}

