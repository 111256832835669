body {
  font-family: $base-font-family;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
}

h1,
.h1 {
  font-size: 1.625rem;
  line-height: 2rem;
  margin-bottom: 2.5rem;
  text-transform: uppercase;
  letter-spacing: 0.03em;
}

h2 {
  font-size: 1.25rem;
  line-height: 1.5rem;
  letter-spacing: 0.03em;
}

@include media-breakpoint-down(md) {
  html {
    //font-size: 10px;
  }
  h2 {
    font-size: 1.4rem;
  }
}

.info-text {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  a {
    text-decoration: underline;
  }
}

.typography-title {
  font-weight: 700;
  font-size: 22px;
  line-height: 27px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
}
