.ngb-datepicker-custom {
  --light: #{$color-light-blue};
  --info: #{$color-white};

  background-color: $color-light-blue;
  user-select: none;
  border: 0 none;
  border-radius: 4px;

  padding-left: 3.5rem;
  padding-right: 3.5rem;
  padding-bottom: 3.8125rem;

  .ngb-dp-header {
    height: 3.375rem;

    ngb-datepicker-navigation {
      margin-top: 1.375rem;

      .ngb-dp-navigation-chevron {
        color: $color-dark-brown;
      }

      ngb-datepicker-navigation-select {
        display: none;
      }
    }

  }

  .ngb-dp-month {
    margin-right: 3.5rem;

    &:last-child {
      margin-right: 0;
    }

    .ngb-dp-month-name {
      font-size: 1rem;
      line-height: 1.5rem;
      font-weight: 600;
      height: 2.375rem;
    }

    &:first-child {
      .ngb-dp-week {
        padding: 0;
      }
    }

    &:last-child {
      .ngb-dp-week {
        padding: 0;
      }
    }

    .ngb-dp-week {
      &:last-child {
        //display: none;
      }

      .ngb-dp-weekday {
        font-weight: 700;
        font-style: normal;
        text-transform: uppercase;

        &.small {
          font-size: 0.625rem;
          line-height: 1rem;
          margin-left: 0.5rem;

          &:first-child {
            margin-left: 0;
          }
        }
      }

      .ngb-dp-day {
        margin-left: 0.5rem;
        margin-bottom: 0.9375rem;
        &.disabled{
          opacity: 0.6;
        }
        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          .range {
            &::before {
              display: none;
            }
          }
        }
      }

      .custom-day {
        border-radius: 100%;
        text-align: center;
        font-size: 0.875rem;
        line-height: 1.375rem;
        padding: 0.312rem 0.25rem;
        display: inline-block;
        height: 2rem;
        width: 2rem;
        display: block;
        position: absolute;
        z-index: 1;

        &:hover {
          background: $color-dark-brown;
        }

        &.range {
          &::before {
            content: "";
            display: block;
            width: 40px;
            height: 32px;
            background: $color-light-brown;
            position: absolute;
            left: 16px;
            top: 0;
            z-index: -2;
          }

          &::after {
            content: "";
            background-color: $color-dark-brown;
            border-radius: 100%;
            width: 32px;
            height: 32px;
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
          }

          &.last, &.first {
            &::before {
                display: none;
              }
            }

          &.faded {
            background-color: $color-light-brown;
            border-radius: 100%;

            &::after {
              display: none;
            }
          }
        }
      }
    }
  }
}
