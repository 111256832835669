@import 'variables';

i[ng-reflect-ngb-tooltip] {
  cursor: pointer;
  color: color('tooltip text');
}

.tooltip-inner {
  padding: 16px;
  color: color('tooltip text');
  background: color('tooltip background');
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.2px;

  a {
    color: color('primary');
  }
}

.tooltip-arrow {
  &::before {
    .bs-tooltip-top & {
      border-top-color: color('tooltip background');
    }
    .bs-tooltip-end & {
      border-right-color: color('tooltip background');
    }
    .bs-tooltip-bottom & {
      border-bottom-color: color('tooltip background');
    }
    .bs-tooltip-start & {
      border-left-color: color('tooltip background');
    }
  }
}

.bs-tooltip-auto {
  &[data-popper-placement^='top'] {
    @extend .bs-tooltip-top;
  }
  &[data-popper-placement^='right'] {
    @extend .bs-tooltip-end;
  }
  &[data-popper-placement^='bottom'] {
    @extend .bs-tooltip-bottom;
  }
  &[data-popper-placement^='left'] {
    @extend .bs-tooltip-start;
  }
}
