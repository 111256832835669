.row {

  &.last {
    margin-bottom: 3.125rem;

    &-padded {
      padding-top: 6.5rem;
    }

  }
}